import React, { Component } from 'react';
import PropTypes from "prop-types";
import { commaSeperator } from '../../utils/methods';
import { Task, MainContainer, Header, SliderContainer, Content, RecommendedSteps, Button, ContinueButton, 
  SelectedSteps, StepsPerDay } from './styles';
import { withTranslation } from 'react-i18next';

class Step1 extends Component {

  render() {
    const { onContinue, steps, changeInputValue, t } = this.props;

    return (
      <div>
        <Task>{t("Set Your Steps Goal")}</Task>
        <MainContainer>
          <Header>
            <SelectedSteps width={1}>
              <img src={'/public/images/steps.png'}/>
              <div>{commaSeperator(steps)}</div>
            </SelectedSteps>
            <StepsPerDay>
              <span>{t("Steps")}</span>
            </StepsPerDay>
          </Header>
          <Content>
            <SliderContainer color={'#3088BD'} value={(steps / 50000)*100}>
              <input className={"slider"} type={"range"} min={1} max={50000} onChange={(e) => changeInputValue(e)} value={steps}/>
              <span className="zero">0</span>
              <span className="steps">{`${commaSeperator(50000)} ${t("Steps")}`}</span>
            </SliderContainer>
            <RecommendedSteps>{`${commaSeperator(10000)} ${t("Steps")}`}<div>{t("Recommended Step Goal")}</div></RecommendedSteps>
          </Content>
        </MainContainer>
        <Button>
          <ContinueButton onClick={onContinue}>
            {t("Continue")}
          </ContinueButton>  
        </Button>
      </div>
    )
  }
}

Step1.propTypes = {
  steps: PropTypes.number,
  changeInputValue: PropTypes.func,
  onContinue: PropTypes.func,
  t: PropTypes.func
};

export default (withTranslation()(Step1));
