import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Task, MainContainer, Header, SliderContainer, Content, RecommendedSteps, Button, ContinueButton, 
  SelectedSteps, StepsPerDay } from './styles';
import { withTranslation } from 'react-i18next';

class Step3 extends Component {

  render() {
    const { onContinue, distance, changeInputValue, steps, calories, t } = this.props;

    return (
      <div>
        <Task>{t("Set Your Distance Average")}</Task>
        <MainContainer>
          <Header>
            <SelectedSteps>
              <img src={'/public/images/location.png'} />
              <div>{distance} {t("mi")}</div>
            </SelectedSteps>
            <StepsPerDay>
              <span>{t("Distance")}</span>
            </StepsPerDay>
          </Header>
          <Content>
            <SliderContainer color={'#3088BD'} value={(distance/30)*100}>
              <input className="slider" type={"range"} min={1} max={30} step={0.1} onChange={(e) => changeInputValue(e)} value={distance}/>
              <span className="zero">0</span>
              <span className="steps">30 {t("Miles")}</span>
            </SliderContainer>
            <RecommendedSteps>
              5.0 {t("Miles")}
              <div>{t("Recommended Distance Target")}</div>
            </RecommendedSteps>
          </Content>
        </MainContainer>
        <Button>
          <ContinueButton 
            onClick={onContinue} 
            disabled={ steps === 0 || calories === 0 || distance === 0}>
            {t("Continue")}
          </ContinueButton>  
        </Button>
      </div>
    )
  }
}

Step3.propTypes = {
  steps: PropTypes.number,
  calories: PropTypes.number,
  distance: PropTypes.number,
  changeInputValue: PropTypes.func,
  onContinue: PropTypes.func,
  t: PropTypes.func
};

export default (withTranslation()(Step3));
