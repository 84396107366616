import React, { Component } from 'react';
import PropTypes from "prop-types";
import { commaSeperator } from '../../utils/methods';
import { Task, MainContainer, Header, SliderContainer, Content, RecommendedSteps, Button, ContinueButton, 
  SelectedSteps, StepsPerDay } from './styles';
import { withTranslation } from 'react-i18next';  

class Step2 extends Component {

  render() {
    const { onContinue, calories, changeInputValue, t } = this.props;

    return (
      <div>
        <Task>{t("Set Your Calorie Goal")}</Task>
        <MainContainer>
          <Header>
            <SelectedSteps>
              <img src={'/public/images/calories.png'} />
              <div>{commaSeperator(calories)}</div>
            </SelectedSteps>
            <StepsPerDay>
              <span>{t("Calories")}</span>
            </StepsPerDay>
          </Header>
          <Content>
            <SliderContainer color={'#3088BD'} value={(calories/3000)*100}>
              <input className="slider" type={"range"} min={1} max={3000} onChange={(e) => changeInputValue(e)} value={calories}/>
              <span className="zero">0</span>
              <span className="steps">{`${commaSeperator(3000)} ${t("Calories")}`}</span>
            </SliderContainer>
            <RecommendedSteps>
              500 {t("Calories")}
              <div>{t("Recommended Calorie Goal")}</div>
            </RecommendedSteps>
          </Content>
        </MainContainer>
        <Button>
          <ContinueButton onClick={onContinue}>
            {t("Continue")}
          </ContinueButton>  
        </Button>
      </div>
    )
  }
}

Step2.propTypes = {
  calories: PropTypes.number,
  changeInputValue: PropTypes.func,
  onContinue: PropTypes.func,
  t: PropTypes.func
};

export default (withTranslation()(Step2));
