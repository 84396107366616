/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { imgPath } from '../../utils/constants';
import { commaSeperator } from '../../utils/methods';
import { GreatingHeader, FinalGoals, ContinueButton, Button, GoalsContainer, SelectedValues } from './styles';
import { withTranslation } from 'react-i18next';

class FinalStep extends Component {

  render() {
    const { onSubmit, userName, month, steps, distance, calories, t } = this.props;

    return (
      <div>
        <GreatingHeader>
          {`${t("Congrats")} ${userName} ${t("on setting your")} ${month} ${t("Fitness goals")}!`}
        </GreatingHeader>
        <FinalGoals>
          <div className="header">{t("My Goals")}</div>
          <GoalsContainer>
            <SelectedValues>
              <img src={`${imgPath}/NewDashboardImages/Footprint.png`} alt="Steps" stepImage />
              <div className="value">{commaSeperator(steps)}</div>
              <span>{t("Total Steps")}</span>
            </SelectedValues>
            <SelectedValues margin="0 40px" width="27px" height="37px">
              <img 
                src={`${imgPath}/NewDashboardImages/calories_colored.png`}
                alt="Calories Left"
                width="50px"
                height="50px"
              />
              <div className="value">{commaSeperator(calories)}</div>
              <span>{t("Calorie Burned")}</span>
            </SelectedValues>
            <SelectedValues width="27px" height="37px">
              <img src={`${imgPath}/NewDashboardImages/distanceNew.png`} alt="Distance" stepImage />
              <div className="value">{commaSeperator(distance)} {t("miles")}</div>
              <span>{t("Total Distance")}</span>
            </SelectedValues>
          </GoalsContainer>
        </FinalGoals>
        <Button>
          <ContinueButton onClick={onSubmit} finalStep>
            {t("Done")}
          </ContinueButton>  
        </Button>
      </div>
    )
  }
}

FinalStep.propTypes = {
  steps: PropTypes.number,
  calories: PropTypes.number,
  distance: PropTypes.number,
  changeInputValue: PropTypes.func,
  onSubmit: PropTypes.func,
  month: PropTypes.string,
  userName: PropTypes.string,
  t: PropTypes.func
};

export default (withTranslation()(FinalStep));
