import React, { Component } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { connect } from 'react-redux';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import FinalStep from './FinalStep';
import { setMonthlyGoal } from '../../redux/actions';
import { StyledModal, StyledHeader, StepsContainer, MonthlyGoalsSteps, ConnectingLine, CloseIcon } from './styles';
import { withTranslation } from 'react-i18next';

class InputGoalsV2 extends Component {
  constructor() {
    super();
    this.state = {
      steps: 8000,
      calories: 300,
      distance: 8,
      activeStep: 1,
    }
  }

  componentDidMount() {
    const d = new Date;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    this.setState({
      date: `${year}-${month < 10 ? `0${month}`:month}-01`
    })
  }

  changeStepsValue = (e) => {
    this.setState({
      steps: e.target.value
    })
  };

  changeCaloriesValue = (e) => {
    this.setState({
      calories: e.target.value
    })
  };

  changeDistanceValue = (e) => {
    this.setState({
      distance: e.target.value
    })
  };

  updateMonthlyGoal = () => {
    const { setMonthlyGoal } = this.props;
    let obj = {};
    obj['month_start_date'] = this.state.date;
    obj['monthly_steps'] = this.state.steps;
    obj['monthly_calories'] = this.state.calories;
    obj['monthly_distance'] = this.state.distance;
    setMonthlyGoal(obj);
  };

  onClosePopup = () => {
    this.setState({
      steps: _.get(this.props.inputGoals, 'daily_step', 0),
      calories: _.get(this.props.inputGoals, 'daily_calorie', 0),
      distance: _.get(this.props.inputGoals, 'daily_distance', 0),
      activeStep: 1
    },() => {
      this.props.onHide()
    })
  }

  onSubmit = () => {
    const { updatedFitnessGoals } = this.props
    if(updatedFitnessGoals)updatedFitnessGoals();
    this.setState({
      activeStep: 1
    });
    this.props.onHide();
    window.location.reload();
  };

  onContinue = () => {
    const { activeStep } = this.state;
    if (activeStep === 3) {
      this.updateMonthlyGoal();
    }
    if (activeStep < 5) {
      this.setState({
        activeStep: activeStep + 1
      });
    }
  };

  navigateUser = (step) => {
    this.setState({
      activeStep: step
    });
  };

  render() {
    const { showModal, month, userName, t } = this.props;
    const { steps, activeStep, calories, distance } = this.state;
    
    return (
      <StyledModal show={showModal} onHide={this.onClosePopup}>
        <div className="background">
          <StyledHeader>
            <div style={{padding:"0px 35px"}}>{t("Your Monthly Fitness Goals")}</div>
            <div>{t("Dashboard / Goals Setting")}</div>
          </StyledHeader>
          <CloseIcon className="closeIcon">
            <img src="/public/images/NewDashboardV2/crossIconNew.png" width="20px" alt="" style={{ "cursor": "pointer" }} onClick={this.onClosePopup} />
          </CloseIcon>
          { activeStep !== 4 &&
            <StepsContainer>
              <MonthlyGoalsSteps
                active={activeStep >= 1}
                onClick={() => this.navigateUser(1)}
              >
                1
              </MonthlyGoalsSteps>
              <ConnectingLine />
              <MonthlyGoalsSteps
                active={activeStep >= 2}
                onClick={() => this.navigateUser(2)}
              >
                2
              </MonthlyGoalsSteps>
              <ConnectingLine />
              <MonthlyGoalsSteps
                active={activeStep >= 3}
                onClick={() => this.navigateUser(3)}
              >
                3
              </MonthlyGoalsSteps>
            </StepsContainer>
          }
          { activeStep === 1 && 
            <Step1 
              onContinue={this.onContinue}
              steps={steps}
              changeInputValue={this.changeStepsValue}
            />
          }
          { activeStep === 2 && 
            <Step2
              onContinue={this.onContinue}
              calories={calories}
              changeInputValue={this.changeCaloriesValue}
            />
          }
          { activeStep === 3 && 
            <Step3 
              onContinue={this.onContinue}
              steps={steps}
              calories={calories}
              distance={distance}
              changeInputValue={this.changeDistanceValue}
            />
          }
          { activeStep === 4 &&
            <FinalStep 
              steps={steps}
              calories={calories}
              distance={distance}
              userName={userName}
              month={month}
              onSubmit={this.onSubmit}
            />
          }
        </div>
      </StyledModal>
    )
  }
}

InputGoalsV2.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  SetDailyGoal: PropTypes.func.isRequired,
  inputGoals: PropTypes.object,
  month: PropTypes.string,
  userName: PropTypes.string,
  setMonthlyGoal: PropTypes.func,
  monthlyGoal: PropTypes.object,
  updatedFitnessGoals: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  monthlyGoal: state.profileData.monthlyGoal
});

const mapDispatchToProps = (dispatch) => ({
  setMonthlyGoal: (monthlyGoalData) => dispatch(setMonthlyGoal(monthlyGoalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InputGoalsV2));
