import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledBody = styled(Modal.Body)`
  padding: 0;
  .pdf {
    max-width: 600px;
    height: ${({isIE}) => isIE ? '600px' : 'auto'};
    max-height: ${({isIE}) => isIE ? '290%' : '100%'};
    margin: 15px;
    .react-pdf__Document {
      padding: 10px;
      >div {
        height: ${({isIE}) => isIE ? '200px' : 'auto'};
        >canvas {
          width: 100% !important;
          height: ${({isIE}) => isIE ? '290%' : '100%'} !important;
        }
      }
    }
  }
`;

export { StyledBody };